import { useEffect } from "react";
import { db } from "../config/firebase-config";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";

// Hook pour récupérer les infos utilisateur et mettre à jour Firebase
export const useGetUserInfo = () => {
  const { name, profilePhoto, userID, isAuth, emailVerified } =
    JSON.parse(localStorage.getItem("auth")) || {};

  // Fonction pour mettre à jour l'état de l'utilisateur dans Firestore
  const updateUserStatus = async () => {
    if (!userID) return;

    try {
      await setDoc(
        doc(db, "users", userID), 
        {
          lastSeen: serverTimestamp(), // Horodatage de la reconnexion
          isOnline: true
        }, 
        { merge: true } // Évite d'écraser les autres données
      );
    } catch (error) {
      console.error("Erreur de mise à jour de l'utilisateur :", error);
    }
  };

  // Mettre à jour Firestore à chaque reconnexion
  useEffect(() => {
    if (isAuth) {
      updateUserStatus();
    }
  // eslint-disable-next-line
  }, [isAuth, userID]); 

  return { name, profilePhoto, userID, isAuth, emailVerified };
};
